@font-face {
	font-family: 'Helvetica Now';
	src: url('./fonts/helvetica_now.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
  font-family: 'Helvetica Now Bold';
  src: url('./fonts/Monotype  - Helvetica Now Display Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

#tos-dialog-title-vr {
  padding: 0px !important;
}
#tos-dialog-actions-vr button {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.hover-layer-disabled {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
}
.hover-layer-disabled:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.hover-layer-selected {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.hover-layer-selected:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
